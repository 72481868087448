@import '../../settings/theme-variables';

/**  =====================
      button css start
==========================  **/
.btn-page {
    .btn {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .btn-group,
    .btn-group-vertical {
        .btn {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

.btn.btn-icon {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 50%;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn-flat {
}

.btn-primary, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: $primary-color;
    border-color:  $primary-color;
}
.btn-primary:hover,  .show > .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: $hover-color;
  border-color: $hover-color;
}

.btn-primary.disabled, .btn-primary:disabled  {
  background-color: $dark-color;
  border-color: $dark-color;
}

.btn-outline-primary {
  color: $primary-color;
  border-color: $primary-color;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: $primary-color;
  background-color: $primary-color;

}